.checkbox {
  position: relative;
  display: inline-block;
  // padding: 0 0.8rem;

  label {
    width: 40px;
    height: 20px;
    background: #ccc;
    position: relative;
    display: inline-block;
    border-radius: 46px;
    transition: 0.4s all;
    &:after {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      left: 0;
      top: -5px;
      z-index: 0;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: 0.4s all;
    }
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    &:hover + label:after {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2),
        0 3px 8px 0 rgba(0, 0, 0, 0.15);
    }
    &.toggle + label {
      &:after {
        left: 40px;
      }
    }
  }
}

.model-3 {
  .checkbox {
    label {
      background: #fff;
      border: 1px solid #eee;
      height: 20px;
      &:after {
        background: #bbb;
        top: 3px;
        left: 5px;
        width: 12px;
        height: 12px;
      }
    }
    span.toggle + label {
      background: #cbf051;
      &:after {
        background: #99bf18;
        left: 21px;
      }
    }
  }
}

// .model-4 {
//   .checkbox {
//     label {
//       background: #bbb;
//       height: 15px;
//       width: 40px;
//       &:after {
//         background: #fff;
//         top: -3.5px;
//         width: 23px;
//         height: 23px;
//       }
//     }
//     span.toggle + label {
//       background: #36b37e;
//       &:after {
//         background: #37fca8;
//         left: 20px;
//       }
//     }
//   }
// }
