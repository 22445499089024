.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.sidepane {
  .active-tab p {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translate(-50%, -50%);
      left: 0;
      width: 2px;
      height: 40px;
      background-color: #99bf18;
    }
  }
}

.full-page {
  min-height: calc(100vh - 140px);
}

.tips-section {
  background: #f9ffeb;
  border: 1px solid #8cb13d;
  border-radius: 2px;
}

.main-section {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
}
.form-group {
  label small {
    color: black;
    font-weight: bold;
  }
  & > * + * {
    margin-top: 0.5rem;
  }
}
.payment-form {
  .flex {
    display: flex;
  }
  .form-group,
  .form-group label {
    display: flex;
    flex-direction: column;
  }

  .form-group + .form-group {
    margin-top: 1.25rem;
  }

  .form-group {
    label small:first-child {
      color: black;
      font-weight: bold;
    }
    & > * + * {
      margin-top: 0.5rem;
    }
  }

  .form-group + .form-group {
    margin-top: 1.25rem;
  }

  .form-group {
    label small {
      color: black;
      font-weight: bold;
    }
    & > * + * {
      margin-top: 0.5rem;
    }
  }

  .form-group label input {
    margin-top: 0.5rem;
  }

  input {
    padding: 1rem 1rem;
    border: 1px solid #e7eaf4;
    border-radius: 4px;
    // max-width: 360px;
  }

  button[type="submit"] {
    background: #99bf18;
    color: white;
    box-shadow: 0px 1px 19px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 100%;
    max-width: 360px;
  }
}
.max-wallet-lg {
  max-width: 42rem;
}

.modal {
  position: fixed;
  top: 0;
}

.modal .prev {
  position: absolute;
  left: 20px;
  top: 25px;
  cursor: pointer;
}

.radio-card {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 1rem;
  display: flex;
  align-items: center;
  min-width: 320px;
  cursor: pointer;

  &--checked {
    background: rgba(140, 177, 61, 0.12);
    border: 1px solid #8cb13d;
  }

  input[type="radio"] {
    background: #ffffff;
    border: 1px solid #8cb13d;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 15px;
    position: relative;
    -webkit-appearance: none;
  }
  input[type="radio"]:after {
    // background-color: #fff;
    border-radius: 25px;
    content: "";
    display: block;
    height: 7px;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    width: 7px;
  }
  input[type="radio"]:checked {
    background: #f1f1f1;
    &::after {
      background-color: #8cb13d;
    }
  }
}

.bg-dollar {
  background-color: #000000;
}

.provider-form-container {
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}
