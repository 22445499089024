.billPayment-container {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  padding: 1rem;
  margin-bottom: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.billPayment-item {
  //   width: 142px;
  //   height: 142px;
  border-radius: 8px;
}

.billPayment-card {
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // grid-gap: 24px;
  // grid-template-columns: 1fr 1fr;
  // place-items: center;
  // grid-row-gap: 2rem;
}

.payment-icon {
  border-radius: 8px;
  width: 100%;
  min-width: 110px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  background: #e7efd6;
}

.payment-text {
  text-align: center;
  padding: 10px 0;
}
