.plain-b {
  height: 20px;
  width: 20px;
  background: #f1f1f1;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.plain-two {
  height: 20px;
  width: 20px;
  border: 1px solid #8cb13d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plain-inner {
  height: 11px;
  width: 11px;
  background: #8cb13d;
  border-radius: 50%;
}
.good-time {
  border: 1px solid #e6e6e6;
}
.blessed {
  border: 1px solid #8cb13d;
  background: #8cb13d0b;
}

/* .custom-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 44, 44, 0.6);
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
} */
