.complete-profile--wrapper {
  position: fixed;
  right: 40px;
  top: 40px;
  max-width: 375px;
  border: solid #99bf17 1px;

  & .progress {
    margin-top: 1.25rem;
  }
}

.animated-fade-enter {
  opacity: 0.2;
  transition: ease-in opacity 1000ms;
}

.animated-fade-enter-active {
  opacity: 1;
}

.animated-fade-exit {
  opacity: 1;
}

.animated-fade-exit-active {
  opacity: 0;
  transition: ease-out opacity 1000ms;
}

@media only screen and (max-width: 1200px) {
  .complete-profile--wrapper {
    top: 100px;
  }
}
